// Imports => Constants
import { KEYS, TITLES } from '@constants';

// Imports => Utilities
import { AcRequestTransformer } from '@utils';

// Get ENV variables
const _api_ = process.env.API_URL;
const _mode_ = process.env.MODE;
const _public_url_ = process.env.PUBLIC_URL;
const _locale_ = process.env.LOCALE || 'en-GB';
const _gtm_key_ = process.env.GTM_KEY;
const _app_url_ = process.env.APP_URL;
const _app_name_ = process.env.APP_NAME || TITLES.BASE;
const _max_file_size_ = process.env.MAX_FILE_SIZE || 209715200; // 100 MiB
const _maps_key_ = process.env.GMAP_KEY;
const _maps_map_id = process.env.GMAP_ID;
const _chuck_ = process.env.CHUCK_NORRIS || false;

const _email_ = process.env.EMAIL;
const _password_ = process.env.PASSWORD;

const _auto_logout = process.env.AUTO_LOGOUT || false;
const _auto_logout_time = process.env.AUTO_LOGOUT_TIME || 20;
const _session_expires_in = process.env.SESSION_EXPIRES_IN || 20;

(function () {
	if (typeof window.CustomEvent === 'function') return false; //If not IE

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(
			event,
			params.bubbles,
			params.cancelable,
			params.detail
		);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.CustomEvent = CustomEvent;
})();

export default {
	chuck: _chuck_,
	mode: _mode_,
	public_url: _public_url_,
	locale: _locale_,
	email: _email_,
	password: _password_,
	gtmId: _gtm_key_,
	apiUrl: _api_,
	appUrl: _app_url_,
	appName: _app_name_,
	maxFileSize: _max_file_size_,
	maps_key: _maps_key_,
	maps_map_id: _maps_map_id,
	noSessionEvent: new CustomEvent(KEYS.NO_SESSION),
	session_expires_in: _session_expires_in,
	autologout: {
		active: _auto_logout || false,
		time: _auto_logout_time || 20,
	},
	rollbar: {
		accessToken: process.env.ROLLBAR_KEY,
		captureUncaught: true,
		captureUnhandledRejections: true,
		verbose: false,
		payload: {
			environment: process.env.ROLLBAR_ENVIRONMENT,
		},
	},
	api: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'access-control-allow-origin': '*',
		},
		transformRequest: [AcRequestTransformer],
	},
	api_no_auth: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'access-control-allow-origin': '*',
		},
	},
	plain: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseEncoding: 'utf8',
		headers: {
			'access-control-allow-origin': '*',
		},
		transformRequest: [AcRequestTransformer],
	},
	download: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		responseType: 'blob',
		responseEncoding: 'utf8',
		transformRequest: [AcRequestTransformer],
	},
	upload: {
		baseURL: _api_,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: 'application/json',
			type: 'formData',
		},
		transformRequest: [
			(data, headers) => AcRequestTransformer(data, headers, false),
		],
	},
};
